import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit'
import notificationsActions from '../features/notifications/actions'
import { handle401Error } from '../libs/auth'

import store from '../store'

import { ServicesEndpointName } from './constants'

export const rtkQueryErrorManager: Middleware = () => next => action => {
	// RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
	if (isRejectedWithValue(action) /* && action.payload.status !== 401 */) {
		if (
			action.payload.status === 401 &&
			action.meta.arg.endpointName !== ServicesEndpointName.CheckInactivityLogin
		) {
			handle401Error({ status: Number(action.payload.status) })
		} else {
			store.dispatch<any>(
				notificationsActions.addNotification({
					type: 'error',
					message: `Error calling the ${action.type.split('/')[0]}, ${
						action.meta.arg.endpointName
					} method`,
					autoClose: true,
					errorType: 'http',
					messageIsLabelKey: true,
				}),
			)
		}
	}
	return next(action)
}
