import { memoize } from 'lodash'

import { ContactLensesCatalogData } from '../../model/exam'
import { RootState } from '../../model/model'

export const selectContactLensesCatalog = (
	state: RootState,
	filterEnabled: boolean = true,
): ContactLensesCatalogData[] => {
	const catalog = state.contactLenses.catalog

	if (filterEnabled) {
		return catalog.filter(item => item.enabled === true)
	}

	return catalog
}

export const selectContactLensData = memoize(
	(catalogId: string) => (state: RootState) =>
		state.contactLenses.catalog.find(({ _id }) => _id === catalogId),
)

export const selectSameProductBothEyesEnabledByTab = (state: RootState) => state.contactLenses.sameProductBothEyesEnabledByTab
