import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import theme from '../styleguide/theme'
import authActions from '../features/auth/actions'
import appActions from '../features/app/actions'
import { useTeloRouter } from '../routing/teloRouter'
import { useTeloDispatch } from '../store'
import Button from '../styleguide/buttons/Button'
import { PageWrapper } from '../styleguide/CommonPageComponents'
import { IMAGES } from '../utils/assetRegistry'

const Container = styled(PageWrapper)`
	background-color: ${theme.palette.background.secondary};
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`

const Logo = styled.img`
	width: 33%;
	height: auto;
	display: block;
	margin-bottom: ${theme.spacing(4)};
`

const Error = styled.div`
	color: ${theme.palette.grey[500]};
	font-size: 0.8rem;
	width: 90%;
	margin: ${theme.spacing(5)} auto ${theme.spacing(4)};
	text-align: center;
	&:first-letter {
		text-transform: uppercase;
	}

	> * {
		margin-bottom: ${theme.spacing(2)};
	}

	details p {
		text-align: left;
	}
`

const Buttons = styled.div`
	display: flex;
	justify-content: center;
`

const AuthErrorPage = () => {
	const { t } = useTranslation()
	const dispatch = useTeloDispatch()
	const { navigate } = useTeloRouter()

	return (
		<Container>
			<Logo alt="Luxottica Optometrist" src={IMAGES.CONNECT_LOGO_URL} />

			<Error>
				<div>{t('errors.authError')}</div>
			</Error>

			<Buttons>
				<Button
					onClick={() => {
						dispatch(authActions.logoutAction())
						dispatch(appActions.removeStoreId())
						navigate('/login')
						window.location.reload()
					}}
				>
					{t('app.backToLogin')}
				</Button>
			</Buttons>
		</Container>
	)
}

export default AuthErrorPage
