import { Grid } from '@mui/material'
import styled, { css } from 'styled-components'
import { pxToRem } from '../../libs/style'
import Box from '../../styleguide/Box'
import Button from '../../styleguide/buttons/Button'
import { disableEditingInput } from '../../styleguide/CommonPageComponents'
import theme from '../../styleguide/theme'
import Subtitle from '../../styleguide/typography/Subtitle'
import Text from '../../styleguide/typography/Text'
import { Field, Form } from 'formik'
import AddIcon from '../../styleguide/icons/AddIcon'
import ChevronRightIcon from '../../styleguide/icons/ChevronRightIcon'
import Disclaimer from '../Disclaimer'

export const rowLayout = css`
	display: grid;
	grid-template-columns: 0.3fr 1fr 3fr 1.5fr 3fr 0.8fr;
	column-gap: 2rem;
	row-gap: 1rem;
	padding: 0.7rem 1rem;
	place-items: center start;
	border-bottom: 1px solid ${theme.palette.grey[400]};
`

const rowSummaryLayout = css`
	display: grid;
	grid-template-columns: 0.05fr 1fr 4fr 1fr 1fr 1.5fr;
	column-gap: ${theme.spacing(5)};
	row-gap: ${theme.spacing(2)};
`

const rowConfigLayout = css`
	display: grid;
	grid-template-columns: 0.5fr 5fr 1.5fr 1.5fr;
	column-gap: ${theme.spacing(5)};
	row-gap: ${theme.spacing(2)};
`

const rowLaboratoryResultLayout = css`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 4fr 2fr;
	column-gap: ${theme.spacing(5)};
	row-gap: ${theme.spacing(2)};
`

export const PageTitle = styled(Subtitle)`
	padding-top: ${theme.spacing(2)};
	padding-bottom: ${theme.spacing(2)};
	margin-bottom: ${theme.spacing(2)};
	border-bottom: 1px solid ${theme.palette.grey[400]};
	color: ${theme.palette.secondary.main};
	font-size: ${theme.typography.pxToRem(16)};
	font-weight: 600;
`

export const TableHeader = styled.div<{
	isSummary?: boolean
	isConfigOrder?: boolean
}>`
	${props =>
		props.isSummary
			? rowSummaryLayout
			: props.isConfigOrder
			? rowConfigLayout
			: rowLayout}
	background-color: ${theme.palette.grey[200]};
	border-radius: 12px 12px 0 0;
	font-weight: 600;
	padding: ${theme.spacing(1, 2)};
	font-size: ${theme.typography.pxToRem(14)};
`

export const GreyLabel = styled.span`
	color: ${theme.palette.violet[900]};
	font-weight: 600;
`

export const AlignEnd = styled(Box)`
	display: flex;
	justify-content: flex-end;
`

export const TableBody = styled.div``

export const RowHeader = styled.div<{
	isSummary?: boolean
	isConfigOrder?: boolean
}>`
	${props =>
		props.isSummary
			? rowSummaryLayout
			: props.isConfigOrder
			? rowConfigLayout
			: rowLayout}

	margin: ${theme.spacing(1)} 0;
	font-size: ${theme.typography.pxToRem(14)};
`

export const RowSimple = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	column-gap: ${theme.spacing(5)};
	row-gap: ${theme.spacing(2)};
	font-size: ${pxToRem(14)}rem;
`

export const DetailsBody = styled.div<{
	$columns: number
	$isSummary?: boolean
}>`
	display: grid;
	grid-template-columns: repeat(${props => props.$columns}, 1fr);
	gap: ${theme.spacing(2)};
	overflow: hidden;
	padding: ${theme.spacing(1, 2)};
	border-bottom: 1px solid ${theme.palette.grey[200]};
	${props =>
		props.$isSummary ? `background-color: ${theme.palette.grey[50]}` : ''};
`

export const DetailsItem = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${theme.spacing(0.5)};
`

export const RowBody = styled.div<{
	$withBorder?: boolean
	$withPaddingTop?: boolean
	$withPaddingBottom?: boolean
}>`
	${rowLayout}
	${props =>
		props.$withBorder
			? `border-bottom: 1px solid ${theme.palette.grey[200]};`
			: ''}
	${props => (props.$withPaddingTop ? `padding-top: ${theme.spacing(0.5)};` : '')}
	${props =>
		props.$withPaddingBottom ? `padding-bottom: ${theme.spacing(2.5)};` : ''}
`

export const RowBodyOrderConfig = styled(Box).attrs(props => ({
	margin: props.margin ?? 2,
}))<{
	$withBorder?: boolean
	$withPaddingTop?: boolean
	$withPaddingBottom?: boolean
}>`
	font-size: ${pxToRem(12)}rem;
	${props =>
		props.$withBorder
			? `border-bottom: 1px solid ${theme.palette.grey[200]};`
			: ''}
	${props =>
		props.$withPaddingTop ? `padding-top: ${theme.spacing(0.5)};` : ''}
	${props =>
		props.$withPaddingBottom ? `padding-bottom: ${theme.spacing(2.5)};` : ''}
`

export const RowLaboratoryResultBody = styled(Box).attrs(props => ({
	mt: props.mt ?? 2,
	mb: props.mb ?? 2,
}))<{
	$withBorder?: boolean
	$withPaddingTop?: boolean
	$withPaddingBottom?: boolean
}>`
	${rowLaboratoryResultLayout};
	font-size: ${pxToRem(12)}rem;
	${props =>
		props.$withBorder
			? `border-bottom: 1px solid ${theme.palette.grey[200]};`
			: ''}
	${props =>
		props.$withPaddingTop ? `padding-top: ${theme.spacing(0.5)};` : ''}
	${props =>
		props.$withPaddingBottom ? `padding-bottom: ${theme.spacing(2.5)};` : ''}
`

export const RowDetailWrapper = styled.div``

export const ResultFormButtonsWrapper = styled(Grid)`
	display: flex;
	align-items: center;
	justify-content: space-between;
`

export const RowBodyFirstColumn = styled.div<{ attachmentRow?: boolean }>`
	grid-column: 1 / 3;
	display: flex;
	padding-left: ${theme.spacing(2)};
	${props => (props.attachmentRow ? `padding-top: ${theme.spacing(1)};` : '')}

	span:first-child {
		margin-right: ${theme.spacing(1)};
	}
`

export const RowBodySecondColumn = styled.div<{ attachmentRow?: boolean }>`
	grid-column: 3 / 6;
	display: flex;
	${props => (props.attachmentRow ? 'justify-content: space-between;' : '')}

	span:first-child {
		margin-right: ${theme.spacing(1)};
	}
`

export const PlusIconStyled = styled(AddIcon)`
	width: ${pxToRem(16)}rem;
	height: ${pxToRem(16)}rem;
`

export const ChevronRightIconStyled = styled(ChevronRightIcon)`
	width: ${pxToRem(18)}rem;
	height: ${pxToRem(18)}rem;
`

export const ValueResultColumn = styled.div`
	grid-column: 1 / 2;
	display: flex;
	padding-left: ${theme.spacing(2)};
`

export const UnitResultColumn = styled.div`
	grid-column: 2 / 3;
	display: flex;
	padding-left: ${theme.spacing(2)};
`

export const RangeResultColumn = styled.div`
	grid-column: 3 / 4;
	display: flex;
	padding-left: ${theme.spacing(2)};
`

export const AbnormalFlagResultColumn = styled.div`
	grid-column: 4 / 5;
	display: flex;
	padding-left: ${theme.spacing(2)};
`

export const ReportDateResultColumn = styled.div`
	grid-column: 5 / 6;
	display: flex;
	padding-left: ${theme.spacing(2)};
`

export const ButtonResultColumn = styled.div`
	grid-column: 6 / 7;
	display: flex;
	justify-content: end;
	padding-right: ${theme.spacing(2)};
`

export const DisabledField = styled(Field)`
	.MuiInputBase-root.Mui-disabled {
		background-color: ${theme.palette.grey[200]};
	}

	.MuiOutlinedInput-root.Mui-disabled.Mui-disabled
		.MuiOutlinedInput-notchedOutline,
	.MuiOutlinedInput-notchedOutline.Mui-disabled,
	.MuiInputBase-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
		border-color: ${theme.palette.grey[200]};
	}
`

export const RadioGroupWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${theme.spacing(1)};
`

export const StyledDisabledInput = styled.div`
	${disableEditingInput}
`

export const CapitalizedLabel = styled.div<{
	onlyFirstLetter?: boolean
	verticalCentered?: boolean
}>`
	${props =>
		props.verticalCentered
			? `
		display: flex;
		align-items: center;
	`
			: ''}

	& > span.lowercase {
		text-transform: lowercase;
	}

	${props =>
		props.onlyFirstLetter
			? `
		&::first-letter {
			text-transform: uppercase;
		}
	`
			: 'text-transform: capitalize;'}
`

export const CircleLoader = styled.div`
	border: 2px solid #f3f3f3;
	border-top: 2px solid #555;
	border-radius: 50%;
	width: ${pxToRem(12)}rem;
	height: ${pxToRem(12)}rem;
	-webkit-animation: spin 1s linear infinite;
	animation: spin 1s linear infinite;

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`

export const AttachmentsAndNoteWrapper = styled.div`
	padding-top: ${theme.spacing(2)};
`

export const NotesWrapper = styled(Box)`
	padding-top: 0;
`

export const UploadAttachmentText = styled(Text)`
	color: ${theme.palette.grey[500]};
	margin-bottom: 0;
`

const attachmentRowLayout = css`
	display: grid;
	grid-template-columns: 5fr 2fr 2fr 5fr;
	column-gap: ${theme.spacing(5)};
	row-gap: ${theme.spacing(2)};
`

export const AttachmentTableHeader = styled.div`
	${attachmentRowLayout};
	padding-bottom: ${theme.spacing(3)};
	padding-left: ${theme.spacing(2)};
	padding-top: ${theme.spacing(3)};
	border-bottom: 1px solid ${theme.palette.grey[200]};
	background-color: ${theme.palette.grey[200]};
	align-items: center;
`

export const AttachmentRowHeader = styled.div`
	${attachmentRowLayout};
	padding-left: ${theme.spacing(2)};
	align-items: center;
`

export const DownloadButton = styled(Button)<{ marginRight?: number }>`
	${props =>
		props.marginRight
			? `margin-right: ${theme.spacing(props.marginRight)};`
			: ''}
	svg path {
		fill: ${theme.palette.secondary.main};
	}
	&.Mui-disabled {
		svg path {
			fill: ${theme.palette.grey[400]};
		}
	}
`

export const BoxImages = styled.div`
	display: flex;
	gap: ${pxToRem(15)}rem;
`

export const ImageBox = styled.div`
	width: 33%;
	height: ${pxToRem(100)}rem;
	overflow: hidden;
	position: relative;
	border-radius: ${pxToRem(10)}rem;
	border: 1px solid;
	cursor: pointer;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
`

export const StyledSvgButton = styled(Button)`
	.MuiButton-startIcon {
		border: 1px solid ${theme.palette.secondary.main};
		border-radius: 50%;
		color: ${theme.palette.secondary.main};

		svg {
			fill: ${theme.palette.secondary.main};
			width: ${pxToRem(16)}rem;
			height: ${pxToRem(16)}rem;
		}
	}
`

export const StyledUnderlinedTextButton = styled(Button)<{
	$buttonDelete?: boolean
}>`
	${props => (props.$buttonDelete ? 'color: #eb392c;' : '')}
`

export const PaddedGrid = styled(Grid)`
	padding-left: ${theme.spacing(2)};
	padding-right: ${theme.spacing(2)};
`

export const PaddedForm = styled(Form)<{ pb?: number }>`
	padding-left: ${theme.spacing(2)};
	padding-right: ${theme.spacing(2)};
	${({ pb }) => pb && `padding-bottom: ${theme.spacing(pb)};`}
`

export const ImageUploadInfo = styled.span`
	display: block;
	font-size: ${pxToRem(12)}rem;
	line-height: 1.5;
	color: ${theme.palette.grey[500]};
	font-weight: normal;
	margin: ${theme.spacing(1.5)} 0 ${theme.spacing(0.75)};
`

export const FlexColumn = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
`

export const FlexGrow = styled.div`
	flex-grow: 1;
`

export const FieldWrapper = styled(Box)`
	display: flex;
	gap: ${pxToRem(10)}rem;
`

export const OrdersCopyright = styled(Disclaimer)`
	transform: translateY(${theme.spacing(-0.5)});
`

export const StyledGrid = styled(Grid)`
	&& {
		padding-top: ${theme.spacing(1)};
		margin-bottom: ${theme.spacing(2)};
	}
`
