import { ExamPages } from '../model/exam'

export const getDefaultPagesValues = (): Readonly<ExamPages> => ({
	summary: {
		mainPage: true,
		summaryAll: true,
		chiefCompliant: true,
		diagnosis: true,
		refraction: true,
		RXPrescribed: true,
		trialCL: true,
		iop: true,
		images: true,
		orders: true,
		imunizations: true,
		cda: true,
	},
	dif: {
		chiefComplaint: true,
		tab: true,
		medicationHistory: true,
		intakeForm: true,
		insuranceInfo: true,
		familyAndPatientHistory: true,
		visionStatus: true,
		lifestyle: true,
		personalInfo: true,
		myopiaCare: true,
	},
	pretest: {
		mainPage: true,
		retinalImaging: true,
		oct: true,
		lensmeter: true,
		historicalRx: true,
		autorefraction: true,
		tonometer: true,
		visualFields: true,
		pupil: true,
		binocularAssessment: true,
		videoSlitLamp: true,
		vitalsData: true,
		visualAcuities: true,
		unaidedVisualAcuities: true,
		stereoTest: true,
		colorTest: true,
		bloodPressure: true,
	},
	refraction: {
		mainPage: true,
		refraction: true,
		retinoscopy: true,
		subjectiveRefraction: true,
		cycloplegicRefraction: true,
		finalRefraction: true,
		myopia: true,
	},
	auxiliary: {
		mainPage: true,
		vergeancesHorizontal: true,
		vergeancesVertical: true,
		pursuitsAndSaccades: true,
	},
	contactLenses: {
		mainPage: true,
	},
	ocularHealth: {
		mainPage: true,
	},
	surgery: {
		mainPage: true,
	},
	otherTest: {
		mainPage: true,
	},
	visionTherapy: {
		mainPage: true,
	},
	assessmentPlan: {
		mainPage: true,
		assessmentPlan: true,
		procedure: true,
		implantableDevices: true,
		screeningProcedures: true,
		goals: true,
		codesToBill: true,
	},
	consultation: {
		mainPage: true,
		clinicalData: true,
		prescription: true,
		additionalForms: true,
		diseasesAndTreatments: true,
	},
	orders: {
		mainPage: true,
	},
	EHRExport: {
		mainPage: true,
	},
	attachment: {
		mainPage: true,
	},
})

export const hiddenPretestPanels = ['retinalImaging', 'oct', 'videoSlitLamp']

export const additionalPretestPanels = ['pupilTesting', 'binocularAssessment']

export const additionalDifPanels = ['myopiaCare']

export const DifFormKeys = {
	personalInfo: [
		'firstName',
		'lastName',
		'cellPhone',
		'homePhone',
		'email',
		'dateOfBirth',
		'age',
		'address',
		'city',
		'state',
		'zipCode',
		'pmoc',
		'race',
		'interpreter',
		'gender',
		'genderOther',
		'language',
		'assistanceRequirements',
		'assistanceRequirementsText',
		'insurance',
	],
	insuranceInfo: [
		'insuranceProvider',
		'memberId',
		'lastSSN',
		'primaryMember',
		'primaryMemberFirstName',
		'primaryMemberLastName',
		'primaryMemberDOB',
		'primaryMemberSSN',
		'aaa',
		'medicalInsuranceProvider',
		'aarp',
		'medicalInsuranceMemberId',
		'hmo',
	],
	healthHistory: [
		'healthConditions',
		'eyeDisease',
		'eyeDiseaseEntry',
		'medicalConditions',
		'medicalConditionsEntry',
		'aarp',
		'alcohol',
		'smoking',
		'allergies',
		'allergiesEntry',
		'pregnancy',
		'nursing',
		'allergiesList',
		'noAllergies',
		'tobaccoHistory',
		'tobaccoLastSmoked',
		'tobaccoStartDate',
		'tobaccoEndDate',
		'tobaccoStatus',
		'tobaccoConsueling',
		'tobaccoReason',
		'medications',
		'medicationsEntry',
	],
	visionHistory: [
		'visionIssues',
		'currentEyewear',
		'prescriptionYear',
		'contactLensInterest',
		'contactLensType',
		'lastEyeExam',
		'hasExtraVisionInfo',
		'shareExtraVisionInfo',
	],
	patient: [
		'workEnvironment',
		'otherWorkEnvironment',
		'screenTime',
		'hobbies',
		'otherHobbies',
	],
	myopiaCare: [
		'ethnicity',
		'timeSpentNearVision',
		'lifeEnvironment',
		'timeSpentOutside',
		'familyHistory',
	],
}
