import { createSelector } from '@reduxjs/toolkit'
import { compose, filter, flatMap, map, prop, uniq } from 'lodash/fp'

import { isApacRegion } from '../../libs/region'
import { isValidDateFormat } from '../../libs/time'
import {
	privilegesEnabledForReport,
	privilegesEnabledForStores,
} from '../../libs/users'
import { Id, RootState } from '../../model/model'
import { Room, Store } from '../../model/store'
import { selectIsOTTMode, selectStoreId } from '../app/selectors'
import { selectCurrentlyDisplayedExam, selectExam } from '../exams/selectors'
import {
	selectIsAdmin,
	selectIsPracticeManager,
	selectManagerPractices,
	selectUser,
} from '../users/selectors'
import { selectPractices } from '../practices/selectors'
import {
	PracticeCooperation,
	StoreCooperation,
	User,
	UserPrivilege,
} from '../../model/users'
import { Practice, PracticesStore } from '../../model/practice'

import { StoresScope } from './types'

export const selectStore = (state: RootState): Store | undefined => {
	const storeId = selectStoreId(state)
	return state.stores[storeId]
}

export const selectStoreByStoreId =
	(storeId: string) =>
	(state: RootState): Store | undefined => {
		return state.stores[storeId]
	}

const _selectStores = (state: RootState) => state.stores
export const selectStores = createSelector(_selectStores, stores =>
	Object.values(stores),
)

export const allUserStores = createSelector(
	selectStores,
	selectUser,
	(stores, user): Store[] => {
		if (user) {
			const userStoreIds = user.stores.map(({ store }) => store._id)
			return stores.filter(({ _id }) => userStoreIds.includes(_id))
		}

		return []
	},
)

export const userStoresWithLEMprivileges = createSelector(
	allUserStores,
	selectManagerPractices,
	selectPractices,
	(userStores, practicesAsLem, allPractices): Store[] => {
		const practices = allPractices.filter(
			({ _id: leId }) =>
				practicesAsLem.map(({ _id }) => _id).includes(leId) as boolean,
		)
		const practicesStoresIds = practices.flatMap(({ stores }) =>
			stores.map(({ _id }) => _id),
		)
		const stores = userStores.filter(({ _id }) =>
			practicesStoresIds.includes(_id),
		)
		return stores
	},
)

export const selectInstrumentsStages = (state: RootState, storeId?: string) => {
	const safeStoreId = storeId || state.app.storeId
	return state.stores[safeStoreId] ? state.stores[safeStoreId].stages : []
}

export const selectPhoroptersRooms = (
	state: RootState,
	storeId?: string,
): Room[] => {
	const safeCode = storeId || state.app.storeId
	const stages = state.stores[safeCode]?.stages

	if (stages === undefined || stages.length === 0) {
		return []
	}

	const phoropterStage = stages.filter(({ rooms }) =>
		rooms.find(({ phoropter }) => !!phoropter),
	)
	const rooms = phoropterStage
		?.flatMap(({ rooms }) => rooms)
		.filter(({ phoropter }) => !!phoropter)

	return rooms
}

export const selectExamRoom =
	(examId: Id, storeId?: string) => (state: RootState) => {
		const storeStages = selectInstrumentsStages(state, storeId)
		return storeStages
			.flatMap(({ rooms }) => rooms)
			.find(({ lockedByExamId }) => lockedByExamId === examId)
	}

export const selectExamPhoropterRoom = (examId: Id) => (state: RootState) => {
	const exam = selectExam(examId)(state)
	if (!exam) {
		return undefined
	}
	const storeId = exam.store._id
	const storePhoropters = selectPhoroptersRooms(state, storeId)
	return storePhoropters?.find(
		({ lockedByExamId }) => lockedByExamId === examId,
	)
}

export const selectExamStore = (examId: Id) => (state: RootState) => {
	const exam = selectExam(examId)(state)
	if (!exam) {
		return undefined
	}
	const storeId = exam.store._id
	return selectStoreByStoreId(storeId)(state)
}

export const selectWebexId = (examId: Id) => (state: RootState) => {
	const store = selectExamStore(examId)(state)
	const roomWithWebexId = store?.stages
		.flatMap(({ rooms }) => rooms)
		.find(room => room.webexId)
	return roomWithWebexId?.webexId
}

export const selectAzureConnection = (examId: Id) =>
	createSelector(selectDeviceAvailableRooms(examId), doctorDeviceRooms => {
		const selectedRoom = doctorDeviceRooms.find(
			({ doctorRoomLockedByExamId }) => doctorRoomLockedByExamId === examId,
		)

		return selectedRoom?.azureCommunication
	})

export const selectRoom = (roomId: Id, storeId?: Id) => (state: RootState) => {
	const store = storeId
		? selectStoreByStoreId(storeId)(state)
		: selectStore(state)
	const room = store?.stages
		.flatMap(({ rooms }) => rooms)
		.find(({ _id }) => _id === roomId)
	return room
}

export const selectStage = (stageId: Id, storeId: Id) => (state: RootState) => {
	const stages = selectInstrumentsStages(state, storeId)
	return stages.find(({ _id }) => _id === stageId)
}

export const selectStoreStages = (storeId: Id) => (state: RootState) =>
	selectInstrumentsStages(state, storeId)

export const selectRoomAlreadyListeningForConfiguration =
	(storeId: Id) => (state: RootState) => {
		const stages = selectInstrumentsStages(state, storeId)
		return stages
			.flatMap(({ rooms }) => rooms)
			.find(({ listeningForConfiguration }) => listeningForConfiguration)
	}

export const selectRefractionistEnabledOnStore = createSelector(
	selectStore,
	store => !!store?.enableRefractionist,
)
export const selectPrivacyPolicyDocuments = (state: RootState) =>
	state.stores[selectStoreId(state)]?.privacyPolicyDocuments

export const selectPrivacyPolicyDocument =
	(documentID: Id) => (state: RootState) => {
		const safeCode = selectStoreId(state)

		return state.stores[safeCode]?.privacyPolicyDocuments.find(
			({ _id }) => documentID === _id,
		)
	}

export const selectPrivacyPolicyDocumentBlob = (state: RootState) =>
	state.stores[selectStoreId(state)]?.blobDocumentUrl

export const selectEnableChat = createSelector(
	selectStore,
	store => store?.enableChat || false,
)

export const selectEnableChatByExamId = (examId: Id) => (state: RootState) => {
	const exam = selectExam(examId)(state)
	if (!exam) {
		return false
	}
	const storeId = exam.store._id
	return state.stores[storeId]?.enableChat || false
}

export const selectStoresForLEA = createSelector<
	[(state: RootState) => User | undefined, (state: RootState) => Practice[]],
	Store[] | []
>(selectUser, selectPractices, (user, practices) => {
	if (!user) {
		return []
	}

	const practicesIdsForLEA = compose<
		[PracticeCooperation[]],
		PracticeCooperation[],
		string[]
	>(
		map('practice'),
		filter(({ privileges }) =>
			Boolean(privileges?.includes(UserPrivilege.LegalEntityManager)),
		),
	)(user.practices)

	const practicesStoresIds = compose<
		[Practice[]],
		Practice[],
		PracticesStore[],
		string[],
		string[]
	>(
		uniq,
		map<PracticesStore, string>(prop<PracticesStore, '_id'>('_id')),
		flatMap<Practice, PracticesStore>(prop('stores')),
		filter(({ _id }) => practicesIdsForLEA.includes(_id)),
	)(practices)

	return compose<[StoreCooperation[]], Store[], Store[]>(
		filter(({ _id }) => practicesStoresIds.includes(_id)),
		map('store'),
	)(user.stores)
})

export const selectStoresForUserWithPrivileges = createSelector<
	[
		(state: RootState) => User | undefined,
		(_: RootState, scope: StoresScope) => StoresScope,
	],
	Store[] | []
>(
	selectUser,
	(_, scope) => scope,
	(user, scope) => {
		if (!user) {
			return []
		}

		const storesPrivileges =
			scope === StoresScope.Stores
				? privilegesEnabledForStores
				: privilegesEnabledForReport

		return compose<[StoreCooperation[]], StoreCooperation[], Store[]>(
			map('store'),
			filter(({ privileges }) =>
				privileges.some(p => storesPrivileges.includes(p)),
			),
		)(user.stores)
	},
)

export const selectEnabledStores = createSelector<
	[
		(state: RootState) => Store[],
		(state: RootState) => User | undefined,
		(state: RootState) => boolean,
		(state: RootState) => boolean,
		(state: RootState) => Store[] | [],
		(state: RootState, scope: StoresScope) => Store[] | [],
	],
	Store[] | []
>(
	selectStores,
	selectUser,
	selectIsAdmin,
	selectIsPracticeManager,
	selectStoresForLEA,
	selectStoresForUserWithPrivileges,
	(stores, user, isAdmin, isLEA, storesForLEA, storesForUserWithPrivileges) => {
		if (!user || !stores?.length) {
			return []
		}

		if (isAdmin) {
			return stores
		}

		if (isLEA) {
			return storesForLEA
		}

		return storesForUserWithPrivileges
	},
)

export const selectSelectedStoreId = (state: RootState): string => {
	const storeId = selectStoreId(state)
	return storeId
}

export const selectSelectedStoreHasCockpitEnabled = (
	state: RootState,
): boolean => {
	const storeId = selectStoreId(state)
	const store = state.stores[storeId]
	if (!store) {
		return false
	}
	return store.enableCockpit
}

export const isNotInMaintenanceModeStoreSelector =
	(state: RootState) =>
	(store: Store): boolean => {
		return !!store?.stages.filter(
			stage =>
				!!stage.rooms.filter(room => !!room.listeningForConfiguration).length,
		).length
	}

export const selectIsClusterSelected = (examId: Id) => (state: RootState) => {
	const stages = selectInstrumentsStages(state)
	const isOttMode = selectIsOTTMode(state)
	const selectedStageId =
		stages.length === 1
			? stages[0]._id
			: stages.find(
					({ rooms }) =>
						!!rooms.find(({ lockedByExamId }) => lockedByExamId === examId) ||
						isOttMode,
			  )?._id || ''
	return !!selectedStageId
}

export type ExamWithStore = { store: Store }

const DEFAULT_DATE_FORMAT = isApacRegion ? 'dd/MM/yyyy' : 'MM/dd/yyyy'

/**
 * Select date from the following sources, in order:
 * 1. from the store of the exam passed in, if any
 * 2. from the store, if the user is in a store
 * 3. from currently displayed exam, if user is not in a store
 *
 * @param examId the exam to lookup the store from, at step 1
 * @returns the date format to be used across the application
 */
export const selectDateFormat =
	(exam?: ExamWithStore) =>
	(state: RootState): string => {
		let dateFormat: string | null = null
		dateFormat = selectDateFormatFromExam(exam)()
		if (dateFormat) {
			return dateFormat
		}
		dateFormat = selectDateFormatFromStore(state)
		if (dateFormat) {
			return dateFormat
		}
		dateFormat = selectDateFormatFromCurrentlyDisplayedExam(state)
		return dateFormat ?? DEFAULT_DATE_FORMAT
	}

const selectDateFormatFromCurrentlyDisplayedExam = (
	state: RootState,
): string | null => {
	const exam = selectCurrentlyDisplayedExam(state)
	if (!exam) {
		return null
	}
	const dateFormat = exam.store?.dateFormat
	const isValid = isValidDateFormat(dateFormat)
	if (!isValid) {
		return null
	}
	return dateFormat
}

const selectDateFormatFromStore = (state: RootState): string | null => {
	const storeId = selectSelectedStoreId(state)
	const selectedStore = storeId ? state.stores?.[storeId] : null
	if (!selectedStore) {
		return null
	}
	const storeDateFormat = selectedStore.dateFormat
	const isValid = isValidDateFormat(storeDateFormat)
	if (!isValid) {
		return null
	}
	return storeDateFormat
}

const selectDateFormatFromExam =
	(exam?: ExamWithStore) => (): string | null => {
		if (!exam) {
			return null
		}
		const examStore = exam?.store
		const dateFormatFromInputExam = examStore?.dateFormat
		const isValid = isValidDateFormat(dateFormatFromInputExam)
		if (!isValid) {
			return null
		}
		return dateFormatFromInputExam
	}

export const selectDeviceAvailableRooms = (examId: string) =>
	createSelector(
		selectExamStore(examId),
		store =>
			store?.stages
				.flatMap(stage =>
					stage.rooms.map(room => ({
						...room,
						combinedName: `${stage.name} - ${room.name}`,
					})),
				)
				.filter(({ doctorRoom }) => doctorRoom === true)
				.map(room => ({
					...room,
					usedByAnotherExam:
						Boolean(
							room.doctorRoomLockedByExamId &&
								room.doctorRoomLockedByExamId !== examId,
						) ||
						Boolean(room.lockedByExamId && room.lockedByExamId !== examId) ||
						room.listeningForConfiguration,
				})) || [],
	)

type instrumentKey =
	| 'autorefraction'
	| 'biometer'
	| 'keratometer'
	| 'lensmeter'
	| 'oct'
	| 'phoropter'
	| 'retinalImaging'
	| 'slitLamp'
	| 'tonometer'
	| 'visualFields'

export const selectExamRoomContainingInstrument =
	(examId: Id, instrument: instrumentKey) => (state: RootState) => {
		const exam = selectExam(examId)(state)
		const storeStages = selectInstrumentsStages(state, exam?.store._id)
		const lockedRoom = storeStages
			.flatMap(({ rooms }) => rooms)
			.find(({ lockedByExamId }) => lockedByExamId === examId)

		if (lockedRoom === undefined) {
			return false
		}

		if (
			typeof lockedRoom === 'object' &&
			lockedRoom.hasOwnProperty(instrument)
		) {
			return true
		}

		return false
	}
