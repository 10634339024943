import { styled as muiStyled } from '@mui/material/styles'
import MDialogActions, {
	DialogActionsProps as MDialogActionsProps,
} from '@mui/material/DialogActions'

export type DialogActionsProps = MDialogActionsProps & {
	justifyContent?: 'space-around' | 'flex-end' | 'center' | 'space-between'
}

const DialogActions = muiStyled(MDialogActions, {
	shouldForwardProp: prop => prop !== 'justifyContent',
})<DialogActionsProps>(({ theme: { palette }, justifyContent }) => ({
	justifyContent: justifyContent ?? 'flex-end',
	textTransform: 'uppercase',

	'.MuiButton-text *': {
		color: palette.violet[900],
		fontWeight: 'bold',
	},

	'.MuiButton-text.Mui-disabled *': {
		opacity: 0.5,
		color: palette.grey[500],
	},
}))

export default DialogActions
