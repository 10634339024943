import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit'
import notificationsActions from '../features/notifications/actions'
import store from '../store'

export const rtkQueryErrorLogger: Middleware = () => next => action => {
	// RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
	if (isRejectedWithValue(action)) {
		store.dispatch<any>(
			notificationsActions.addNotification({
				type: 'error',
				message: `Error calling the ${action.type.split('/')[0]}, ${
					action.meta.arg.endpointName
				} method`,
				autoClose: true,
				errorType: 'http',
				messageIsLabelKey: true,
			}),
		)
	}
	return next(action)
}
