import { createApi } from '@reduxjs/toolkit/query/react'

import { staggeredBaseQueryWithBailOut } from '../libs/services'
import { unversionedApiUrl } from './common'
import { ServiceBaseUrl } from './serviceApi'
import { ServicesApiTag, ServicesReducerPath } from './constants'
import { PrivacyPolicyDocumentSignedV2 } from '../model/signedPrivacyPolicyDocuments'

export const signedPrivacyPolicyDocumentsApi = createApi({
	reducerPath: ServicesReducerPath.SignedPrivacyPolicyDocuments,
	tagTypes: [ServicesApiTag.SignedPrivacyPolicyDocuments],
	baseQuery: staggeredBaseQueryWithBailOut(unversionedApiUrl),
	endpoints: builder => ({
		getSignedPrivacyPolicyDocuments: builder.query<
			PrivacyPolicyDocumentSignedV2[],
			string
		>({
			query: patientId => ({
				url: `${ServiceBaseUrl.SignedPrivacyPolicyDocuments}/${patientId}`,
			}),
			providesTags: [ServicesApiTag.SignedPrivacyPolicyDocuments],
		}),
	}),
})

export const { useGetSignedPrivacyPolicyDocumentsQuery } =
	signedPrivacyPolicyDocumentsApi
