export enum ServicesReducerPath {
	ExamsApi = 'examsApi',
	CdaApi = 'cdaApi',
	AIApi = 'aiApi',
	ContactLensesApi = 'contactLensesApi',
	CodingApi = 'codingApi',
	InactivityLoginApi = 'inactivityLoginApi',
	SignedPrivacyPolicyDocuments = 'signedPrivacyPolicyDocuments',
}

export enum ServicesEndpointName {
	CheckInactivityLogin = 'checkInactivityLogin',
}

export enum ServicesApiTag {
	Cda = 'cda',
	Ai = 'ai',
	ContactLenses = 'contactLenses',
	Coding = 'coding',
	SignedPrivacyPolicyDocuments = 'SignedPrivacyPolicyDocuments',
}
