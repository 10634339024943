import styled from 'styled-components'
import theme from '../styleguide/theme'
import { pxToRem } from '../libs/style'
import DialogActions from '../styleguide/dialog/DialogActions'
import DialogContent from '../styleguide/dialog/DialogContent'

const SIGNATURE_DIALOG_WIDTH = 880
export const SignatureDialogContent = styled(DialogContent)`
	&.MuiDialogContent-root {
		display: grid;
		flex-direction: column;
		gap: ${theme.spacing(3)};
		padding: ${theme.spacing(4)} ${theme.spacing(13)} ${theme.spacing(2)};
		width: ${pxToRem(SIGNATURE_DIALOG_WIDTH)}rem;
		transition: height ${theme.transitions.duration.enteringScreen * 2}
			${theme.transitions.easing.easeIn};
	}
`

export const SignatureDialogContentText = styled.div`
	color: ${theme.palette.primary.main};
	font-size: ${pxToRem(14)}rem;
	font-style: normal;
	font-weight: 400;
	line-height: 140%;
`

export const SignBlockTitle = styled.h5`
	margin-block-start: ${theme.spacing(4)};
	font-size: ${pxToRem(16)}rem;
	font-weight: 500;
	line-height: 1.4;
`

const SIGN_BLOCK_HEIGHT = 240
const SIGN_BLOCK_VERTICAL_PADDING = 20
const SIGN_BLOCK_GAP = 24
const SIGN_BLOCK_FOOTER_HEIGHT = 56
const SIGN_CANVAS_HEIGHT =
	SIGN_BLOCK_HEIGHT -
	SIGN_BLOCK_VERTICAL_PADDING * 2 -
	SIGN_BLOCK_GAP -
	SIGN_BLOCK_FOOTER_HEIGHT

export const SignBlockWrapper = styled.div`
	display: grid;
	gap: ${pxToRem(SIGN_BLOCK_GAP)}rem;
	max-height: ${pxToRem(SIGN_BLOCK_HEIGHT)}rem;
	padding: ${pxToRem(SIGN_BLOCK_VERTICAL_PADDING)}rem
		${pxToRem(SIGN_BLOCK_VERTICAL_PADDING * 2)}rem;
	border: ${pxToRem(1)}rem solid ${theme.palette.grey[800]};
	border-radius: ${theme.spacing(0.5)};

	canvas {
		width: 100%;
		max-height: ${pxToRem(SIGN_CANVAS_HEIGHT)}rem;
	}
`

export const SignBlockFooter = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: ${pxToRem(SIGN_BLOCK_GAP)}rem;
	border-block-start: ${pxToRem(1)}rem solid ${theme.palette.grey[300]};
	padding-block-start: ${theme.spacing(2)};
	height: ${pxToRem(SIGN_BLOCK_FOOTER_HEIGHT)}rem;

	time {
		font-size: ${pxToRem(12)}rem;
		font-weight: 400;
	}

	button {
		padding: ${pxToRem(10)}rem ${pxToRem(16)}rem;

		.textButton--label {
			line-height: 1;
		}

		&[hidden] {
			// needed to prevent layout shift when the button is hidden
			visibility: hidden;
			opacity: 0;
		}
	}
`

export const SignBlockNote = styled.div`
	font-size: ${pxToRem(12)}rem;
	color: ${theme.palette.greyCustom.main};
	margin-block-end: ${theme.spacing(2)};
`

export const SignatureDialogActions = styled(DialogActions)`
	&.MuiDialogActions-root {
		justify-content: center;

		button > .button--label {
			text-transform: capitalize;
		}
	}
`

export const SignatureDialogPDFDocumentWrapper = styled.div`
	.react-pdf__Document {
		overflow: hidden;

		.react-pdf__Page > canvas {
			width: 100% !important;
			height: fit-content !important;
		}
	}
`

export const GuardianBox = styled.div`
	display: flex;
	flex-direction: column;
	& > * {
		margin-bottom: ${theme.spacing(1)};
	}
`

export const GuardianCheckboxes = styled.div`
	display: flex;
	flex-direction: row;
	& > * {
		margin-right: ${theme.spacing(3)};
	}
`

export const GuardianLabel = styled.p`
	color: ${theme.palette.primary.main};
	font-size: ${pxToRem(16)}rem;
	font-style: normal;
	font-weight: 400;
	line-height: 140%;
`