import { Privilege } from '../../model/users'
import Yup from '../../yup'

export const adminDocumentsEditPrivileges: Privilege[] = ['StoreManager']

export const adminDocumentsFormValidationSchema = Yup.object().shape({
	name: Yup.string().required(),
	text: Yup.string().when('type', {
		is: 'text',
		then: () => Yup.string().required(),
	}),
	duration: Yup.number(),
})

export const defaultDocumentDuration = 1
