import {
	Action,
	AnyAction,
	configureStore,
	createListenerMiddleware,
	ThunkAction,
	ThunkDispatch,
} from '@reduxjs/toolkit'
import { CurriedGetDefaultMiddleware } from '@reduxjs/toolkit/dist/getDefaultMiddleware'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

import alerts from './features/alerts/slice'
import app from './features/app/slice'
import appointments from './features/appointments/slice'
import auth from './features/auth/slice'
import azureCommunication from './features/azureCommunication/slice'
import carousel from './features/carousel/slice'
import chat from './features/chat/slice'
import chatbot from './features/chatbot/slice'
import clipboard from './features/clipboard/slice'
import cockpit from './features/cockpit/slice'
import contactLenses from './features/contactLenses/slice'
import { contactsReducer } from './features/contacts/slice'
import coremedia from './features/coremedia/slice'
import countries from './features/countries/slice'
import diagnosiPlans from './features/diagnosiPlans/slice'
import diagnosis from './features/diagnosis/slice'
import dialog from './features/dialog/slice'
import errors from './features/errors/slice'
import exams from './features/exams/slice'
import healthDescriptions from './features/healthDescriptions/slice'
import medicalReportTemplates from './features/medicalReportTemplates/slice'
import { mfeReducer } from './features/mfe/mfeSlice'
import notifications from './features/notifications/slice'
import ocularHealthConditions from './features/ocularHealthConditions/slice'
import { orders } from './features/orders/slice'
import panels from './features/panels/slice'
import patientMatch from './features/patientMatch/slice'
import patients from './features/patients/slice'
import practices from './features/practices/slice'
import prescription from './features/prescription/slice'
import prescriptions from './features/prescriptions/slice'
import queue from './features/queue/slice'
import referralCommons from './features/referralCommons/slice'
import referralDoctors from './features/referralDoctors/slice'
import refractionClassicExam from './features/refractionClassicExam/slice'
import renewal from './features/renewal/slice'
import reportFilters from './features/reportFilters/slice'
import reports from './features/reports/slice'
import roomSelectionPages from './features/roomSelection/slice'
import search from './features/search/slice'
import selectedUsers from './features/selectedUsers/slice'
import ses from './features/ses/slice'
import signDocuments from './features/signDocuments/slice'
import signRequests from './features/signRequests/slice'
import states from './features/states/slice'
import stores from './features/stores/slice'
import tasks from './features/tasks/slice'
import timeouts from './features/timeouts/slice'
import timezones from './features/timezones/slice'
import troubleShooting from './features/troubleShooting/slice'
import ui from './features/ui/slice'
import users from './features/users/slice'
import vaScales from './features/vaScales/slice'
import visualSimulation from './features/visualSimulation/slice'
import worklist from './features/worklist/slice'
import { RootState } from './model/model'
import { aiApi } from './services/ai'
import { anesthesiaCategoriesApi } from './services/anesthesiaCategories'
import { appointmentTypeApi } from './services/appointments'
import { cdaApi } from './services/cda'
import { chiefComplaintCategoryApi } from './services/chiefComplaintCategory'
import { codingApi } from './services/coding'
import { contactLensesApi } from './services/contactLenses'
import { cptApi } from './services/cpt'
import { cvxApi } from './services/cvx'
import { difApi } from './services/dif'
import { emergencyAccessApi } from './services/emergencyAccess'
import { rtkQueryErrorLogger } from './services/errorCatchingMiddleware'
import { rtkQueryErrorManager } from './services/errorManagerMiddleware'
import { examsApi } from './services/exams'
import { examTemplateApi } from './services/examTemplateApi'
import { featuresApi } from './services/features'
import { fhirPatientsApi } from './services/fhirPatients'
import { fieldsDataApi } from './services/fieldsData'
import { fieldsDataMetaApi } from './services/fieldsDataMeta'
import { icd10Api } from './services/icd10'
import { icd10Api as icd10SystemApi } from './services/icd10.system'
import { loincApi } from './services/loinc'
import { manufacturerApi } from './services/manufacturer'
import { modifierApi } from './services/modifier'
import { ndcApi } from './services/ndc'
import { patientsApi } from './services/patients'
import { queueApi } from './services/queue'
import { referralTypeApi } from './services/referralType'
import { sesApi } from './services/ses'
import { snomedctApi } from './services/snomedct'
import { storesApi } from './services/stores'
import { surgeryConditionsApi } from './services/surgeryConditions'
import { usersApi } from './services/users'
import { visDocumentsApi } from './services/visDocuments'
import { worklistApi } from './services/worklist'
import { inactivityLoginApi } from './services/inactivityLogin'
import { timeoutsApi } from './services/timeouts'
import { renewTokenApi } from './services/renewToken'
import { signedPrivacyPolicyDocumentsApi } from './services/signedPrivacyPolicyDocumentsApi'

export const listenerMiddleware = createListenerMiddleware<RootState>()

const reducer = {
	alerts,
	app,
	appointments,
	signDocuments,
	auth,
	azureCommunication,
	carousel,
	chat,
	chatbot,
	clipboard,
	cockpit,
	contactLenses,
	coremedia,
	countries,
	diagnosiPlans,
	diagnosis,
	dialog,
	errors,
	exams,
	healthDescriptions,
	medicalReportTemplates,
	notifications,
	orders,
	panels,
	patientMatch,
	patients,
	practices,
	prescriptions,
	prescription,
	referralCommons,
	referralDoctors,
	renewal,
	reports,
	reportFilters,
	roomSelectionPages,
	selectedUsers,
	search,
	signRequests,
	stores,
	states,
	tasks,
	timeouts,
	timezones,
	troubleShooting,
	ui,
	users,
	contacts: contactsReducer,
	visualSimulation,
	ocularHealthConditions,
	vaScales,
	worklist,
	queue,
	refractionClassicExam,
	mfe: mfeReducer,
	ses,
	[icd10Api.reducerPath]: icd10Api.reducer,
	[snomedctApi.reducerPath]: snomedctApi.reducer,
	[loincApi.reducerPath]: loincApi.reducer,
	[cvxApi.reducerPath]: cvxApi.reducer,
	[ndcApi.reducerPath]: ndcApi.reducer,
	[manufacturerApi.reducerPath]: manufacturerApi.reducer,
	[cptApi.reducerPath]: cptApi.reducer,
	[referralTypeApi.reducerPath]: referralTypeApi.reducer,
	[chiefComplaintCategoryApi.reducerPath]: chiefComplaintCategoryApi.reducer,
	[fieldsDataApi.reducerPath]: fieldsDataApi.reducer,
	[fieldsDataMetaApi.reducerPath]: fieldsDataMetaApi.reducer,
	[icd10SystemApi.reducerPath]: icd10SystemApi.reducer,
	[worklistApi.reducerPath]: worklistApi.reducer,
	[examsApi.reducerPath]: examsApi.reducer,
	[usersApi.reducerPath]: usersApi.reducer,
	[storesApi.reducerPath]: storesApi.reducer,
	[fhirPatientsApi.reducerPath]: fhirPatientsApi.reducer,
	[featuresApi.reducerPath]: featuresApi.reducer,
	[queueApi.reducerPath]: queueApi.reducer,
	[sesApi.reducerPath]: sesApi.reducer,
	[emergencyAccessApi.reducerPath]: emergencyAccessApi.reducer,
	[appointmentTypeApi.reducerPath]: appointmentTypeApi.reducer,
	[modifierApi.reducerPath]: modifierApi.reducer,
	[anesthesiaCategoriesApi.reducerPath]: anesthesiaCategoriesApi.reducer,
	[surgeryConditionsApi.reducerPath]: surgeryConditionsApi.reducer,
	[visDocumentsApi.reducerPath]: visDocumentsApi.reducer,
	[cdaApi.reducerPath]: cdaApi.reducer,
	[aiApi.reducerPath]: aiApi.reducer,
	[examTemplateApi.reducerPath]: examTemplateApi.reducer,
	[patientsApi.reducerPath]: patientsApi.reducer,
	[contactLensesApi.reducerPath]: contactLensesApi.reducer,
	[difApi.reducerPath]: difApi.reducer,
	[codingApi.reducerPath]: codingApi.reducer,
	[inactivityLoginApi.reducerPath]: inactivityLoginApi.reducer,
	[timeoutsApi.reducerPath]: timeoutsApi.reducer,
	[renewTokenApi.reducerPath]: renewTokenApi.reducer,
	[signedPrivacyPolicyDocumentsApi.reducerPath]:
		signedPrivacyPolicyDocumentsApi.reducer,
}

const middleware = (getDefaultMiddleware: CurriedGetDefaultMiddleware) =>
	getDefaultMiddleware()
		.prepend(listenerMiddleware.middleware)
		.concat(
			contactLensesApi.middleware,
			codingApi.middleware,
			aiApi.middleware,
			icd10Api.middleware,
			snomedctApi.middleware,
			loincApi.middleware,
			cvxApi.middleware,
			ndcApi.middleware,
			manufacturerApi.middleware,
			cptApi.middleware,
			referralTypeApi.middleware,
			chiefComplaintCategoryApi.middleware,
			fieldsDataApi.middleware,
			fieldsDataMetaApi.middleware,
			icd10SystemApi.middleware,
			worklistApi.middleware,
			examsApi.middleware,
			usersApi.middleware,
			storesApi.middleware,
			fhirPatientsApi.middleware,
			appointmentTypeApi.middleware,
			featuresApi.middleware,
			queueApi.middleware,
			rtkQueryErrorManager,
			sesApi.middleware,
			appointmentTypeApi.middleware,
			modifierApi.middleware,
			anesthesiaCategoriesApi.middleware,
			surgeryConditionsApi.middleware,
			emergencyAccessApi.middleware,
			visDocumentsApi.middleware,
			cdaApi.middleware,
			patientsApi.middleware,
			rtkQueryErrorLogger,
			examTemplateApi.middleware,
			difApi.middleware,
			inactivityLoginApi.middleware,
			timeoutsApi.middleware,
			renewTokenApi.middleware,
			signedPrivacyPolicyDocumentsApi.middleware,
		)

const store = configureStore({
	reducer,
	middleware,
})

export function getTestStore(initialState: Partial<RootState>) {
	return configureStore({
		reducer,
		middleware,
		preloadedState: initialState,
	})
}

export type TeloGetState = () => RootState
export type TeloDispatch = ThunkDispatch<RootState, any, AnyAction>
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>

export type AppThunkPromise<R = void> = ThunkAction<
	Promise<R>,
	RootState,
	unknown,
	Action<string>
>

export type WithDispatch = {
	dispatch: TeloDispatch
}

export const useTeloDispatch: () => TeloDispatch = useDispatch
export const useTeloSelector: TypedUseSelectorHook<RootState> = useSelector

export default store
