import styled from 'styled-components'
import { pxToRem } from '../libs/style'
import theme from '../styleguide/theme'

const Disclaimer = styled.div<{ mb?: number }>`
	font-size: ${pxToRem(10)}rem;
	text-align: center;
	margin: 0 10%;
	${({ mb }) => mb && `margin-bottom: ${theme.spacing(mb)};`}
`

export default Disclaimer
