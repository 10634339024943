import {
	AutorefractionData,
	AutorefractionDataDayOrNight,
	AutorefractionDataWithAccuracy,
	Eye,
	HistoricalRXData,
	InstrumentRemoteMedia,
	KeratometerData,
	KeratometerDataDayOrNight,
	KeratometerDataWithAccuracy,
	KeratormeterDataEye,
	LensmeterData,
	LensmeterDataWithAccuracy,
	LensmeterSingleEye,
	OCTData,
	PhoropterData,
	PhoropterDataDayOrNight,
	PhoropterDataPart,
	PhoropterDataWithAccuracy,
	RetinalImageData,
	SlitLampData,
	TonometerData,
	TonometerDataEye,
	VisualAcuityBothEye,
	VisualAcuityData,
	VisualAcuitySingleEye,
	VisualFieldsData,
} from '../model/instruments'
import {
	AutorefractionDataApi,
	AutorefractionDataDayOrNightApi,
	AutorefractionDataWithAccuracyApi,
	AutorefractionSingleEyeApi,
	BaseValue,
	KeratometerDataApi,
	KeratometerDataDayOrNightApi,
	KeratometerDataWithAccuracyApi,
	KeratometerSingleEyeApi,
	LensmeterDataApi,
	LensmeterDataWithAccuracyApi,
	LensmeterSingleEyeApi,
	OCTDataApi,
	PhoropterDataApi,
	PhoropterDataDayOrNightApi,
	PhoropterDataPartApi,
	PhoropterDataWithAccuracyApi,
	PhoropterSingleEyeApi,
	RemoteMediaApi,
	RetinalImagingDataApi,
	SlitLampDataApi,
	TonometerDataApi,
	TonometerMeasureApi,
	VisualAcuityBothEyeApi,
	VisualAcuityDataApi,
	VisualAcuitySingleEyeApi,
	VisualFieldsDataApi,
} from '../model/instrumentsApi'

const encodeInstrumentRemoteMedia =
	(eye: Eye) =>
	({
		name,
		format,
		path,
		examStatus,
		createdAt,
	}: InstrumentRemoteMedia): RemoteMediaApi => {
		return {
			name: name,
			eye: eye === 'OS' ? 'L' : 'R',
			format: format,
			path: path,
			createdAt: createdAt,
			status: examStatus,
		}
	}

export const encodeRetinalImageData = ({
	OS,
	OD,
}: RetinalImageData): RetinalImagingDataApi => ({
	L: {
		media: OS.data.map(encodeInstrumentRemoteMedia('OS')),
		note: OS.note,
	},
	R: {
		media: OD.data.map(encodeInstrumentRemoteMedia('OD')),
		note: OD.note,
	},
})

export const encodeOCTData = ({ OS, OD }: OCTData): OCTDataApi => ({
	L: {
		media: OS.media.map(encodeInstrumentRemoteMedia('OS')),
		note: OS.note || '',
		rnfl: OS.rnfl ? Number(OS.rnfl) : undefined,
		gcl: OS.gcl ? Number(OS.gcl) : undefined,
		macThickness: OS.macThickness ? Number(OS.macThickness) : undefined,
		axialLength: OS.axialLength ? Number(OS.axialLength) : undefined,
	},
	R: {
		media: OD.media.map(encodeInstrumentRemoteMedia('OD')),
		note: OD.note || '',
		rnfl: OD.rnfl ? Number(OD.rnfl) : undefined,
		gcl: OD.gcl ? Number(OD.gcl) : undefined,
		macThickness: OD.macThickness ? Number(OD.macThickness) : undefined,
		axialLength: OD.axialLength ? Number(OD.axialLength) : undefined,
	},
})

const mapBase: { [k: string]: BaseValue } = {
	BI: 'BI',
	in: 'BI',
	BO: 'BO',
	out: 'BO',
	BD: 'BD',
	down: 'BD',
	BU: 'BU',
	up: 'BU',
}

const encodeLensometrySingleEye = (
	data: LensmeterSingleEye,
): LensmeterSingleEyeApi => {
	return {
		sphere: data.sphere || '',
		cylinder: data.cyl || '',
		axis: data.axis,
		add: data.add,
		prism: {
			x: {
				base: mapBase[data.hOrient] || '',
				value: data.hPrism,
			},
			y: {
				base: mapBase[data.vOrient] || '',
				value: data.vPrism,
			},
		},
	}
}

const econdeLensmeterDataWithAccuracy = (
	data: LensmeterDataWithAccuracy,
): LensmeterDataWithAccuracyApi => {
	return {
		accuracy: data.accuracy,
		L: encodeLensometrySingleEye(data.OS),
		R: encodeLensometrySingleEye(data.OD),
	}
}

const encodeVisualAcuitySingleEye = (
	data: VisualAcuitySingleEye,
): VisualAcuitySingleEyeApi => {
	return {
		distance: data.distance,
		distanceAdditional: data.distanceAdditional,
		distancePH: data.distancePH,
		near: data.near,
		nearAdditional: data.nearAdditional,
		nearPH: data.nearPH,
	}
}

const encodeVisualAcuityBothEye = (
	data: VisualAcuityBothEye,
): VisualAcuityBothEyeApi => {
	return {
		distance: data.distance,
		distanceAdditional: data.distanceAdditional,
		near: data.near,
		nearAdditional: data.nearAdditional,
	}
}

const encodeVisualAcuityData = (
	data: VisualAcuityData,
): VisualAcuityDataApi => {
	return {
		OD: encodeVisualAcuitySingleEye(data.OD),
		OS: encodeVisualAcuitySingleEye(data.OS),
		BOTH: encodeVisualAcuityBothEye(data.BOTH),
	}
}

export const encodeLensometerData = (data: LensmeterData): LensmeterDataApi => {
	return {
		default: econdeLensmeterDataWithAccuracy(data.default),
		other: data.other ? econdeLensmeterDataWithAccuracy(data.other) : undefined,
		visualAcuity: encodeVisualAcuityData(data?.visualAcuity),
		use: data.use,
		note: data.useNote,
		lensType: data.lensType,
	}
}

export const encodeHistoricalRxData = (
	data: HistoricalRXData[],
): LensmeterDataApi[] =>
	data.map(e => {
		return {
			...encodeLensometerData(e),
			lensAddOns: e.lensAddOns || [],
			lensMaterial: e.lensMaterial || '',
			lensDesign: e.lensDesign || '',
		}
	})

const encodeAutorefractionSingleEye = (
	data: AutorefractionDataWithAccuracy,
	eye: 'OS' | 'OD',
): AutorefractionSingleEyeApi => {
	return {
		sphere: data.eyes[eye].sphere || '',
		cylinder: data.eyes[eye].cyl || '',
		axis: data.eyes[eye].axis,
		pupils: data.eyes[eye].pupils,
	}
}

export const encodeAutorefractionDataWithAccuracy = (
	data: AutorefractionDataWithAccuracy,
): AutorefractionDataWithAccuracyApi => {
	return {
		accuracy: data.accuracy,
		L: encodeAutorefractionSingleEye(data, 'OS'),
		R: encodeAutorefractionSingleEye(data, 'OD'),
	}
}

export const encodeAutorefractionDataDayOrNight = (
	data: AutorefractionDataDayOrNight,
): AutorefractionDataDayOrNightApi => {
	return {
		default: encodeAutorefractionDataWithAccuracy(data.default),
		other: data.other
			? encodeAutorefractionDataWithAccuracy(data.other)
			: undefined,
	}
}

export const encodeAutorefractionData = (
	data: AutorefractionData,
): AutorefractionDataApi => {
	return {
		day: encodeAutorefractionDataDayOrNight(data.day),
		night: data.night
			? encodeAutorefractionDataDayOrNight(data.night)
			: undefined,
		pupillaryDistance: data.pupillaryDistance,
		topography: data.topography
			? data.topography.map(topography =>
					encodeInstrumentRemoteMedia(topography.eye)(topography),
			  )
			: undefined,
		summary: data.summary
			? data.summary.map(summary =>
					encodeInstrumentRemoteMedia(summary.eye)(summary),
			  )
			: undefined,
		visionSimulation: data.visualSimulation
			? data.visualSimulation.map(vs => encodeInstrumentRemoteMedia(vs.eye)(vs))
			: undefined,
		crystalline: data.crystalline
			? data.crystalline.map(crystalline =>
					encodeInstrumentRemoteMedia(crystalline.eye)(crystalline),
			  )
			: undefined,
	}
}

const encodeKeratormeterDataEye = (
	data: KeratormeterDataEye,
): KeratometerSingleEyeApi => {
	return {
		R1: {
			power: data.power,
			axis: data.hMeridian,
		},
		R2: {
			power: data.vPower,
			axis: data.vMeridian,
		},
	}
}

const encodeKeratometerDataWithAccuracy = (
	data: KeratometerDataWithAccuracy,
): KeratometerDataWithAccuracyApi => {
	return {
		accuracy: data.accuracy,
		L: encodeKeratormeterDataEye(data.OS),
		R: encodeKeratormeterDataEye(data.OD),
	}
}
const encodeKeratometerDataDayOrNight = (
	data: KeratometerDataDayOrNight,
): KeratometerDataDayOrNightApi => {
	return {
		default: encodeKeratometerDataWithAccuracy(data.default),
		other: data.other
			? encodeKeratometerDataWithAccuracy(data.other)
			: undefined,
	}
}
export const encodeKeratometerData = (
	data: KeratometerData,
): KeratometerDataApi => {
	return {
		day: encodeKeratometerDataDayOrNight(data.day),
		night: data.night ? encodeKeratometerDataDayOrNight(data.night) : undefined,
	}
}

const encodeTonometerDataEye = (
	data: TonometerDataEye,
): TonometerMeasureApi => ({
	iop1: data.iop1,
	iop2: data.iop2,
	iopc: data.iopc,
})

export const encodeTonometerData = (data: TonometerData): TonometerDataApi => {
	return {
		L: encodeTonometerDataEye(data.OS),
		R: encodeTonometerDataEye(data.OD),
		acquisitionTime: data.OS.time,
		method: data.method,
		notes: data.notes,
		pachymetry: {
			L: {
				media:
					data.pachymetry?.OS.data.map(encodeInstrumentRemoteMedia('OS')) || [],
				note: data.pachymetry?.OS.note || '',
			},
			R: {
				media:
					data.pachymetry?.OD.data.map(encodeInstrumentRemoteMedia('OD')) || [],
				note: data.pachymetry?.OD.note,
			},
		},
	}
}

export const encodeVisualFieldsData = (
	data: VisualFieldsData,
): VisualFieldsDataApi => {
	return {
		L: {
			media: data.OS.data.map(encodeInstrumentRemoteMedia('OS')),
			note: data.OS.note,
		},
		R: {
			media: data.OD.data.map(encodeInstrumentRemoteMedia('OD')),
			note: data.OD.note,
		},
	}
}

export const encodeSlitLampData = ({
	OS,
	OD,
}: SlitLampData): SlitLampDataApi => {
	return {
		L: {
			media: OS.data.map(encodeInstrumentRemoteMedia('OS')),
			note: OS.note,
		},
		R: {
			media: OD.data.map(encodeInstrumentRemoteMedia('OD')),
			note: OD.note,
		},
	}
}

const encodePhoropterSingleEye = (
	data: PhoropterDataWithAccuracy,
	eye: 'OS' | 'OD',
): PhoropterSingleEyeApi => {
	const { ODAdditional, OSAdditional } = data.visualAcuity
	const vaAdditionalOD =
		ODAdditional !== undefined ? Number(ODAdditional) : undefined
	const vaAdditionalOS =
		OSAdditional !== undefined ? Number(OSAdditional) : undefined
	return {
		sphere: data.eyes[eye].sphere || '',
		cylinder: data.eyes[eye].cyl || '',
		axis: data.eyes[eye].axis,
		add: data.eyes[eye].add,
		prism: {
			x: {
				base: mapBase[data.eyes[eye].hOrient] || '',
				value: data.eyes[eye].hPrism,
			},
			y: {
				base: mapBase[data.eyes[eye].vOrient] || '',
				value: data.eyes[eye].vPrism,
			},
		},
		va: data.visualAcuity[eye],
		vaAdditional: eye === 'OD' ? vaAdditionalOD : vaAdditionalOS,
		description: data.eyes[eye].description,
	}
}

export const encodePhoropterDataWithAccuracy = (
	data: PhoropterDataWithAccuracy,
): PhoropterDataWithAccuracyApi => {
	const {
		nearAdditional: $nearAdditional,
		distanceAdditional: $distanceAdditional,
	} = data.both
	const nearAdditional =
		$nearAdditional !== undefined ? Number($nearAdditional) : undefined
	const distanceAdditional =
		$distanceAdditional !== undefined ? Number($distanceAdditional) : undefined

	return {
		accuracy: data.accuracy,
		L: encodePhoropterSingleEye(data, 'OS'),
		R: encodePhoropterSingleEye(data, 'OD'),
		B: {
			va: {
				near: data.both.near || '',
				nearAdditional,
				distance: data.both.distance || '',
				distanceAdditional,
			},
		},
	}
}

export const encodePhoropterDataDayOrNight = (
	data: PhoropterDataDayOrNight,
): PhoropterDataDayOrNightApi => {
	return {
		default: encodePhoropterDataWithAccuracy(data.default),
		other: data.other ? encodePhoropterDataWithAccuracy(data.other) : undefined,
	}
}

export const encodePhoropterDataPart = (
	data: PhoropterDataPart,
): PhoropterDataPartApi => {
	return {
		day: encodePhoropterDataDayOrNight(data.day),
		night: data.night ? encodePhoropterDataDayOrNight(data.night) : undefined,
		note: data.note,
		ph: data.ph ? data.ph : undefined,
		glare: data.glare ? data.glare : undefined,
	}
}

export const encodePhoropterData = (data: PhoropterData): PhoropterDataApi => {
	return {
		subjectiveRefraction: data.subjectiveRefraction
			? encodePhoropterDataPart(data.subjectiveRefraction)
			: undefined,
		cycloplegicRefraction: data.cycloplegicRefraction
			? encodePhoropterDataPart(data.cycloplegicRefraction)
			: undefined,
		finalRx: data.finalRefraction
			? encodePhoropterDataPart(data.finalRefraction)
			: undefined,
		contactLenses: data.contactLenses
			? encodePhoropterDataPart(data.contactLenses)
			: undefined,
		pupillaryDistance: data.pupillaryDistance,
		sensitivity: data.sensitivity || 0,
	}
}
