import React, {
	DetailedHTMLProps,
	HTMLAttributes,
	MouseEvent,
	useCallback,
} from 'react'
import { ChildrenProps } from '../utils/utilityTypes'
import { TeloDestination, useTeloRouter } from './teloRouter'

type SpanProps = DetailedHTMLProps<
	HTMLAttributes<HTMLSpanElement>,
	HTMLSpanElement
>

type TeloLinkProps = SpanProps &
	ChildrenProps & {
		to: TeloDestination
		withStyle?: boolean
	}

export const TeloLink = ({
	to,
	children,
	onClick,
	withStyle,
	...htmlProps
}: TeloLinkProps) => {
	const { navigate: teloNavigate } = useTeloRouter()
	const handleClick = useCallback(
		(e: MouseEvent<HTMLSpanElement>) => {
			teloNavigate(to)
			onClick?.(e)
		},
		[onClick, teloNavigate, to],
	)
	return (
		<span
			{...htmlProps}
			onClick={handleClick}
			style={
				withStyle
					? { color: 'black', fontWeight: 'bold', cursor: 'pointer' }
					: undefined
			}
		>
			{children}
		</span>
	)
}
