import React, { FC } from 'react'

import { SnackbarProps } from '@mui/material/Snackbar'

import { identity } from 'lodash/fp'

import Alert from '../../styleguide/Alert'
import { NotificationSeverity } from '../../model/model'

import { defaultNotificationAlertAnchorOrigin } from './constants'
import { NotificationAlertSnackbar } from './NotificationAlert.styled'

export type NotificationAlertProps = SnackbarProps & {
	severity: NotificationSeverity
	message: string
	isIconVisible?: boolean
	onClose: () => void
}

export const NotificationAlert: FC<NotificationAlertProps> = ({
	isIconVisible = true,
	anchorOrigin = defaultNotificationAlertAnchorOrigin,
	message,
	severity,
	onClose = identity,
}) => (
	<NotificationAlertSnackbar anchorOrigin={anchorOrigin} open>
		<Alert severity={severity} isIconVisible={isIconVisible} onClose={onClose}>
			{message}
		</Alert>
	</NotificationAlertSnackbar>
)
