import { SnackbarProps } from '@mui/material/Snackbar'

import styled from 'styled-components'

import Snackbar from '../../styleguide/Snackbar'

export const NotificationAlertSnackbar = styled(Snackbar)<SnackbarProps>`
	&.MuiSnackbar-anchorOriginTopRight {
		top: 140px;
		right: 0;
	}
`
