import React from 'react'
import { useTranslation } from 'react-i18next'
import dialogActions from '../features/dialog/actions'
import Button from '../styleguide/buttons/Button'
import DialogActions from '../styleguide/dialog/DialogActions'
import DialogContent from '../styleguide/dialog/DialogContent'
import DialogContentText from '../styleguide/dialog/DialogContentText'
import { selectPendingExamId } from '../features/users/selectors'
import { selectUsername } from '../features/auth/selectors'
import examsActions from '../features/exams/actions'
import { ExamStatus } from '../model/exam'
import { useTeloDispatch, useTeloSelector } from '../store'
import authActions from '../features/auth/actions'
import appActions from '../features/app/actions'
import { selectExam } from '../features/exams/selectors'

const LogoutDialog = () => {
	const { t } = useTranslation()
	const dispatch = useTeloDispatch()
	const username = useTeloSelector(selectUsername)
	const doctorPendingExamId = useTeloSelector(selectPendingExamId(username))
	const exam = useTeloSelector(selectExam(doctorPendingExamId || ''))

	return (
		<>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					{t('login.logoutDialogText')}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => dispatch(dialogActions.closeDialog())}
					variant="outlined"
				>
					{t('app.cancel')}
				</Button>
				<Button
					data-testid="ok-quit-logout"
					onClick={() => {
						dispatch(dialogActions.closeDialog())
						doctorPendingExamId &&
							exam?.status !== 'DoctorModeSelected' &&
							dispatch(
								examsActions.setExamStatus({
									id: doctorPendingExamId,
									status: 'Waiting' as ExamStatus,
									username,
								}),
							)
						dispatch(appActions.removeStoreId())
						dispatch(authActions.setAppLoginStatus('logging out'))
					}}
					autoFocus
					variant="contained"
				>
					{t('login.okQuit')}
				</Button>
			</DialogActions>
		</>
	)
}

export default LogoutDialog
