import React from 'react'

import { SvgProps } from '../../model/types'

const ChevronDownIcon: React.FC<SvgProps> = props => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			fill="currentColor"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.7072 15.7077C12.3167 16.0982 11.6835 16.0982 11.293 15.7077L5.63616 10.0508C5.24563 9.66033 5.24563 9.02717 5.63616 8.63664C6.02668 8.24612 6.65985 8.24612 7.05037 8.63664L12.0001 13.5864L16.9498 8.63664C17.3404 8.24612 17.9735 8.24612 18.3641 8.63664C18.7546 9.02717 18.7546 9.66033 18.3641 10.0508L12.7072 15.7077Z"
			/>
		</svg>
	)
}

export default ChevronDownIcon
