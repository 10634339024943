import React, {
	AnchorHTMLAttributes,
	FC,
	ForwardedRef,
	forwardRef,
} from 'react'

import { Button as MButton, ButtonProps as MButtonProps } from '@mui/material'

import styled from 'styled-components'

import theme from '../theme'
import { pxToRem } from '../../libs/style'
import FirstLetterCapital from '../typography/FirstLetterCapital'

export type MyButtonProps = Omit<
	ButtonProps,
	'bold' | 'underlinedText' | 'capitalized'
> & {
	$bold?: boolean
	$underlinedText?: boolean
	$borderWidth?: string
	$removeBoxShadow?: boolean
}

const MyButton = styled(MButton)<MyButtonProps>`
	line-height: ${pxToRem(24)}rem;
	border-radius: 12px;
	padding: 10px ${theme.spacing(2)};
	color: ${({ secondary }) =>
		!secondary ? theme.palette.aquamarine['600'] : theme.palette.violet['900']};
	border-width: ${({ $borderWidth }) => ($borderWidth ? $borderWidth : '')};

	${({ secondary }) =>
		!secondary
			? ''
			: `
		color: ${theme.palette.violet['900']};
		background-color: ${theme.palette.grey['200']};
		border-color: ${theme.palette.violet['900']};
	`}

	&:hover {
		border-width: ${({ $borderWidth }) => ($borderWidth ? $borderWidth : '')};
		background-color: ${({ secondary }) =>
			!secondary ? theme.palette.violet.dark : theme.palette.grey['200']};
	}

	&:active {
		background-color: ${({ secondary }) =>
			!secondary ? theme.palette.violet['800'] : theme.palette.grey['200']};
	}

	&:focus {
		${({ $removeBoxShadow }) =>
			$removeBoxShadow
				? ''
				: `box-shadow: 0 0 0 1px ${theme.palette.background.default}, 0 0 0 3px ${theme.palette.secondary.light};`}
	}

	&.Mui-disabled {
		background-color: ${theme.palette.grey['100']};
		color: ${theme.palette.greyCustom.main};
	}

	&.MuiButton-outlined {
		color: ${theme.palette.primary.main};
		border-color: ${theme.palette.primary.main};
		background-color: ${theme.palette.grey['200']};

		&:hover {
			background-color: ${theme.palette.grey['300']};
		}

		&:active {
			background-color: ${theme.palette.violet['400']};
		}

		&.Mui-disabled {
			background-color: ${theme.palette.grey['100']};
			border-color: ${theme.palette.grey['100']};
			color: ${theme.palette.greyCustom.main};
		}

		.MuiButton-label,
		.button--label {
			${({ $bold }) => ($bold ? `font-weight: bold;` : ``)}
		}
	}
`

const TextButton = styled(MButton)<MyButtonProps>`
	color: ${theme.palette.primary.main};
	font-size: 1rem;
	padding: 4px ${theme.spacing(1)};
	display: flex;
	gap: 0.5rem;
	border-radius: 10px;
	border-width: ${({ $borderWidth }) => $borderWidth ?? ''};

	.MuiButton-startIcon {
		margin: 0;
	}

	&:hover {
		background-color: transparent;
		color: ${theme.palette.grey['800']};
		border-color: ${theme.palette.grey['800']};
		border-width: ${({ $borderWidth }) => $borderWidth ?? ''};
	}

	&:focus {
		box-shadow: ${({ $underlinedText }) =>
			$underlinedText
				? 'none'
				: `box-shadow: 0 0 0 1px ${theme.palette.background.default},
			0 0 0 3px ${theme.palette.secondary.light};`};
	}

	&.Mui-disabled {
		color: ${theme.palette.greyCustom.main};
	}
`

export type ButtonLabelProps = {
	$bold?: boolean
	$capitalize?: boolean
	underlined?: boolean
	$underlinedText?: boolean
	allCaps?: boolean
	lineHeight?: string | number
	fontSize?: string
}

const Label = styled(FirstLetterCapital)<ButtonLabelProps>`
	border-bottom: ${({ underlined }) =>
		underlined ? 'solid 1px currentcolor' : 'none'};
	font-weight: ${({ $bold }) => ($bold ? '600' : '')};
	text-transform: ${({ allCaps, $capitalize }) =>
		allCaps ? 'uppercase' : $capitalize ? 'capitalize' : 'none'};
	text-decoration: ${({ $underlinedText }) =>
		$underlinedText ? 'underline' : ''};
	line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : '')};
	font-size: ${({ fontSize }) => (fontSize ? fontSize : '')};
`

const AsIsLabel = styled.span<ButtonLabelProps>`
	border-bottom: ${({ underlined }) =>
		underlined ? 'solid 1px currentcolor' : 'none'};
	font-weight: ${({ $bold }) => ($bold ? '600' : '')};
	text-transform: ${({ allCaps, $capitalize }) =>
		allCaps ? 'uppercase' : $capitalize ? 'capitalize' : 'none'};
	text-decoration: ${({ $underlinedText }) =>
		$underlinedText ? 'underline' : ''};
	line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : '')};
	font-size: ${({ fontSize }) => (fontSize ? fontSize : '')};
`

export type ButtonProps = Omit<MButtonProps, 'component'> & {
	underlined?: boolean
	underlinedText?: boolean
	loading?: boolean
	component?: string
	bold?: boolean
	allCaps?: boolean
	capitalize?: boolean
	target?: AnchorHTMLAttributes<{}>['target']
	textAsIs?: boolean
	disabled?: boolean
	secondary?: boolean
	lineHeight?: string | number
	borderWidth?: string
	fontSize?: string
	removeBoxShadow?: boolean
}

const Button = forwardRef(
	(p: ButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
		const {
			children,
			loading,
			underlined,
			underlinedText,
			bold,
			allCaps,
			capitalize,
			textAsIs,
			disabled,
			lineHeight,
			borderWidth,
			fontSize,
			removeBoxShadow,
			...props
		} = p

		return props.variant === 'text' ? (
			<TextButton
				{...props}
				ref={ref}
				disableFocusRipple={true}
				disableRipple={true}
				disabled={disabled}
				$underlinedText={underlinedText}
				$bold={bold}
				$borderWidth={borderWidth}
			>
				{textAsIs ? (
					<AsIsLabel
						className="textButton--label"
						underlined={underlined}
						$bold={bold}
						$underlinedText={underlinedText}
						$capitalize={capitalize}
						allCaps={allCaps}
						lineHeight={lineHeight}
						fontSize={fontSize}
					>
						{children}
					</AsIsLabel>
				) : (
					<Label
						className="textButton--label"
						underlined={underlined}
						$underlinedText={underlinedText}
						$bold={bold}
						$capitalize={capitalize}
						allCaps={allCaps}
						lineHeight={lineHeight}
						fontSize={fontSize}
					>
						{children}
					</Label>
				)}
			</TextButton>
		) : (
			<MyButton
				color="primary"
				variant="contained"
				disableElevation
				ref={ref}
				disabled={disabled}
				$bold={bold}
				$underlinedText={underlinedText}
				$borderWidth={borderWidth}
				$removeBoxShadow={removeBoxShadow}
				{...props}
			>
				{textAsIs ? (
					<AsIsLabel
						className="textButton--label"
						underlined={underlined}
						$underlinedText={underlinedText}
						$capitalize={capitalize}
						$bold={bold}
						lineHeight={lineHeight}
						fontSize={fontSize}
					>
						{children}
					</AsIsLabel>
				) : (
					<Label
						className="button--label"
						$capitalize={capitalize}
						underlined={underlined}
						$underlinedText={underlinedText}
						lineHeight={lineHeight}
						fontSize={fontSize}
					>
						{children}
					</Label>
				)}
			</MyButton>
		)
	},
)

export default Button
