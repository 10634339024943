import InputAdornment from '@mui/material/InputAdornment'
import { isEmpty } from 'lodash'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ExpandableButton from '../../components/ExpandableButton'
import {
	TroubleShootingContent,
	TroubleShootingSection,
	TroubleShootingSectionContent,
} from '../../model/troubleShooting'
import { useTeloRouter } from '../../routing/teloRouter'
import Button from '../../styleguide/buttons/Button'
import {
	ExpandableMenuContent,
	ExpandableMenuHolder,
	ExpandableMenuOverlay,
	ExpandableMenuWrapper,
} from '../../styleguide/CommonPageComponents'
import TeloAccordion from '../../styleguide/expansionPanel/TeloAccordion'
import CloseIcon from '../../styleguide/icons/CloseIcon'
import HelpIcon from '../../styleguide/icons/HelpIcon'
import SearchIcon from '../../styleguide/icons/SearchIcon'
import {
	StyledAccordions,
	StyledActions,
	StyledCloseIcon,
	StyledIssue,
	StyledIssuesTitle,
	StyledSearch,
	StyledSingleAccordion,
	StyledTitle,
	StyledTitleAndClose,
	StyledTo,
	StyledTopRow,
} from './styles'

type Props = {
	dataset: TroubleShootingContent[]
	fullDataset: TroubleShootingContent[]
}

const TroubleShooting: React.FC<Props> = ({ dataset, fullDataset }) => {
	const { t } = useTranslation()
	const [open, setOpen] = useState(false)
	const [data, setData] = useState<any>(null)
	const { readRouteParams } = useTeloRouter()

	const handleInput = (ev: ChangeEvent<{ value: string }>) => {
		const value = ev.target.value.toLowerCase()
		const source = value === '' ? dataset : fullDataset
		const newDataset = [
			...source.reduce((d: TroubleShootingContent[], section) => {
				const results = (section.sections || []).filter(
					(s: TroubleShootingSection) => s.title.toLowerCase().includes(value),
				)

				if (results.length) {
					d.push({
						...section,
						sections: results,
					})
				}

				return d
			}, []),
		].sort((a, b) => (a.order || -1) - (b.order || -1))
		setData(newDataset)
	}

	const handleClose = () => {
		setData(dataset)
		setOpen(false)
	}

	useEffect(() => {
		if (!data && !isEmpty(dataset[0])) {
			setData(dataset.sort((a, b) => (a.order || -1) - (b.order || -1)))
		}
	}, [dataset, data])

	return (
		<>
			{open && (
				<ExpandableMenuOverlay onClick={handleClose}></ExpandableMenuOverlay>
			)}
			<ExpandableMenuWrapper
				data-test-id="throubleshooting-overlay-trigger"
				open={open}
			>
				<ExpandableButton
					text={t('troubleShooting.needHelp')}
					isOpen={open}
					onClick={() => setOpen(!open)}
					icon={<HelpIcon />}
				/>
				<ExpandableMenuContent className={open ? 'open' : ''} width={450}>
					<ExpandableMenuHolder>
						<StyledTopRow>
							<StyledTitleAndClose>
								<StyledTitle>{t('troubleShooting.title')}</StyledTitle>
								<StyledCloseIcon
									onClick={handleClose}
									startIcon={<CloseIcon />}
									variant="text"
									color="secondary"
								/>
							</StyledTitleAndClose>
							<StyledSearch
								placeholder={t('troubleShooting.search')}
								onChange={handleInput}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<SearchIcon />
										</InputAdornment>
									),
								}}
							></StyledSearch>
						</StyledTopRow>
						<StyledAccordions>
							{data &&
								data.length > 0 &&
								data.map((d: TroubleShootingContent, index: number) => (
									<TeloAccordion
										key={`issue-${index}`}
										titleResetCase={true}
										fullWidthTitleStyle={true}
										bigTitle={
											<StyledIssuesTitle>
												{d.title} {t('troubleShooting.issues')}
											</StyledIssuesTitle>
										}
										applySubtitleStyle={false}
										removeExpandAllButton={false}
										defaultExpanded={false}
										smallPadding={true}
										emptyState={true}
										panels={[...(d.sections || [])]
											.sort(
												(
													a: TroubleShootingSection,
													b: TroubleShootingSection,
												) => a.order - b.order,
											)
											.reduce(
												(
													sections: TroubleShootingSection[],
													sec: TroubleShootingSection,
												) => {
													sections.push({
														...sec,
														id: sec.sectionKey,
														title: `${d.order}.${sec.order} ${sec.title}`,
														content: (
															<StyledSingleAccordion>
																{sec.data.map(
																	(
																		c: TroubleShootingSectionContent,
																		index: number,
																	) => (
																		<React.Fragment key={`c-${index}`}>
																			{c.description && (
																				<StyledIssue>
																					<h4>
																						{t('troubleShooting.description')}
																					</h4>
																					<p
																						dangerouslySetInnerHTML={{
																							__html: c.description,
																						}}
																					/>
																				</StyledIssue>
																			)}
																			{c.preliminaryCheck && (
																				<StyledIssue>
																					<h4>
																						{t(
																							'troubleShooting.preliminaryCheck',
																						)}
																					</h4>
																					<p
																						dangerouslySetInnerHTML={{
																							__html: c.preliminaryCheck,
																						}}
																					/>
																				</StyledIssue>
																			)}
																			{c.info && (
																				<StyledIssue>
																					<h4>{t('troubleShooting.info')}</h4>
																					<div
																						dangerouslySetInnerHTML={{
																							__html: c.info,
																						}}
																					/>
																				</StyledIssue>
																			)}
																			{c.to && (
																				<StyledIssue>
																					<StyledActions>
																						<StyledTo
																							dangerouslySetInnerHTML={{
																								__html: `<p>${t(
																									'troubleShooting.to',
																								)}:</p> ${c.to}`,
																							}}
																						/>
																						<Button
																							variant="outlined"
																							color="secondary"
																							onClick={() => {
																								window.open(
																									'https://helpdesk.luxottica.com/',
																									'_blank',
																								)
																							}}
																						>
																							{t(
																								'troubleShooting.createTicket',
																							)}
																						</Button>
																					</StyledActions>
																				</StyledIssue>
																			)}
																		</React.Fragment>
																	),
																)}
															</StyledSingleAccordion>
														),
													})
													return sections
												},
												[],
											)}
									/>
								))}
						</StyledAccordions>
					</ExpandableMenuHolder>
				</ExpandableMenuContent>
			</ExpandableMenuWrapper>
		</>
	)
}

export default TroubleShooting
