import React from 'react'
import styled from 'styled-components'

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { Badge } from '@mui/material'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import Popper from '@mui/material/Popper'

import useEclipsMetadata from '../hooks/useEclipsMetadata'
import { pxToRem } from '../libs/style'
import Button from './buttons/Button'
import SettingsIcon from './icons/SettingsIcon'
import theme from './theme'

const PopperStyled = styled(Popper)`
	z-index: 1170 !important;
`
const MenuContainer = styled.div`
	z-index: 1;
`

const MenuButton = styled(Button)`
	padding-right: 0;
`

const MenuPanel = styled.div`
	border-radius: ${theme.spacing(1.5)};
	background-color: ${theme.palette.background.default};
	padding: ${theme.spacing(1)};
	margin-top: ${theme.spacing(0.5)};
	box-shadow: 0 8px 16px -2px rgba(27, 36, 44, 0.12),
		0px 2px 2px -1px rgba(27, 35, 44, 0.04);
`

const StyledMenuItem = styled(MenuItem)<{
	isHeader?: boolean
	isLogout?: boolean
}>`
	overflow: visible;
	border-radius: ${pxToRem(6)}rem;

	padding: ${({ isHeader }) =>
		!isHeader ? `${theme.spacing(0.75)} ${theme.spacing(1)}` : '0'};
	margin: ${({ isHeader }) =>
		isHeader
			? `-${theme.spacing(1)} -${theme.spacing(1)} 0`
			: `${theme.spacing(0.5)} 0`};

	${({ isLogout }) =>
		isLogout
			? `
		border-top: 1px solid ${theme.palette.grey[300]};
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		margin: -${theme.spacing(1)} -${theme.spacing(1)} 0;
		padding: ${theme.spacing(0.75)} ${theme.spacing(2)};
	`
			: ''}

	a {
		font-size: ${pxToRem(14)}rem;
	}
`

type DropdownMenuProps = {
	label?: string
	startIcon?: JSX.Element
	menuItems: JSX.Element[]
	badgeContent?: string
	badgeColor?: 'default' | 'error' | 'primary' | 'secondary'
}

const DropdownMenu = ({
	label,
	startIcon,
	menuItems,
	badgeContent,
	badgeColor = 'primary',
}: DropdownMenuProps) => {
	const [open, setOpen] = React.useState(false)
	const anchorRef = React.useRef<HTMLButtonElement>(null)
	const eclipsMetadata = useEclipsMetadata()
	const isAppInsideEclips = eclipsMetadata !== undefined

	const handleToggle = () => {
		setOpen(prevOpen => !prevOpen)
	}

	const handleClose = (event: any) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return
		}

		setOpen(false)
	}

	function handleListKeyDown(event: React.KeyboardEvent) {
		if (event.key === 'Tab') {
			event.preventDefault()
			setOpen(false)
		}
	}

	// return focus to the button when we transitioned from !open -> open
	const prevOpen = React.useRef(open)
	React.useEffect(() => {
		if (prevOpen.current === true && open === false) {
			anchorRef.current!.focus()
		}

		prevOpen.current = open
	}, [open])

	const connectMenuButton = badgeContent ? (
		<MenuButton
			variant="text"
			underlined={true}
			ref={anchorRef}
			aria-controls={open ? 'menu-list-grow' : undefined}
			aria-haspopup="true"
			onClick={handleToggle}
			startIcon={startIcon}
			endIcon={<ArrowDropDownIcon />}
		>
			<Badge badgeContent={badgeContent} color={badgeColor}>
				{label}
			</Badge>
		</MenuButton>
	) : (
		<MenuButton
			variant="text"
			underlined={true}
			ref={anchorRef}
			aria-controls={open ? 'menu-list-grow' : undefined}
			aria-haspopup="true"
			onClick={handleToggle}
			startIcon={startIcon}
			endIcon={<ArrowDropDownIcon />}
		>
			{label}
		</MenuButton>
	)

	const eclipsMenuButton = (
		<MenuButton
			variant="text"
			underlined={false}
			ref={anchorRef}
			aria-controls={open ? 'menu-list-grow' : undefined}
			aria-haspopup="true"
			onClick={handleToggle}
			startIcon={<SettingsIcon />}
		/>
	)

	return (
		<MenuContainer data-testid="header-dropdown-menu">
			{isAppInsideEclips === true ? eclipsMenuButton : connectMenuButton}
			<PopperStyled
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				placement="bottom-end"
				disablePortal
				style={{
					zIndex: 1000,
				}}
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin:
								placement === 'bottom' ? 'center top' : 'center bottom',
						}}
					>
						<MenuPanel>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList
									autoFocusItem={open}
									id="menu-list-grow"
									onKeyDown={handleListKeyDown}
									disablePadding={true}
								>
									{menuItems.map((item, index) => {
										const isHeader = item.props.className?.includes(
											'profile-menu-header',
										)
										const isLogout = item.props.className?.includes(
											'profile-menu-logout',
										)
										return item.props.isdivider === 'true' ? (
											React.cloneElement(item, { key: index })
										) : (
											<StyledMenuItem
												key={index}
												onClick={handleClose}
												disabled={item.props.disabled}
												data-testid="header-ddm-item"
												isHeader={isHeader}
												isLogout={isLogout}
												disableRipple={isHeader}
											>
												{item}
											</StyledMenuItem>
										)
									})}
								</MenuList>
							</ClickAwayListener>
						</MenuPanel>
					</Grow>
				)}
			</PopperStyled>
		</MenuContainer>
	)
}

export default DropdownMenu
