import { ExamStatus } from './exam'
import { AddDescriptionValues } from './instrumentsApi'
import {
	LensAddOnsData,
	LensDesignData,
	LensMaterialData,
	ManualData,
	PachymetryManualData as ManualPachymetryData,
	PachymetryManualData,
} from './manual'

export interface RetinalImageData {
	OS: {
		data: InstrumentRemoteMedia[]
		note: string
	}
	OD: {
		data: InstrumentRemoteMedia[]
		note: string
	}
}

export type OCTEye = {
	rnfl?: string
	gcl?: string
	macThickness?: string
	axialLength?: string
	media: InstrumentRemoteMedia[]
	note?: string
}

export type OCTData = {
	OD: OCTEye
	OS: OCTEye
	examStatus?: ExamStatus
}

export type HOrient = 'BO' | 'BI' | ''

export type VOrient = 'BU' | 'BD' | ''

export type Eye = 'OS' | 'OD' | ''

export type InstrumentEye = 'OD' | 'OS'

export type DayOrNight = 'day' | 'night'

export interface LensmeterSingleEye {
	sphere: string | null
	cyl: string | null
	axis: string
	add: string
	hPrism: string
	hOrient: HOrient
	vPrism: string
	vOrient: VOrient
	description?: string
}

export interface VisualAcuityBothEye {
	distance: string
	distanceAdditional: string
	near: string
	nearAdditional: string
}

export interface VisualAcuitySingleEye extends VisualAcuityBothEye {
	distancePH: string
	nearPH: string
}

export interface VisualAcuityData {
	OD: VisualAcuitySingleEye
	OS: VisualAcuitySingleEye
	BOTH: VisualAcuityBothEye
}

export interface PhoropterSingleEye {
	sphere: string | null
	cyl: string | null
	axis: string
	add: string
	hPrism: string
	hOrient: HOrient
	vPrism: string
	vOrient: VOrient
	description: AddDescriptionValues
}

export type LensUse =
	| 'fullTime'
	| 'distanceOnly'
	| 'near'
	| 'intermediate'
	| 'computer'
	| 'occupational'
	| 'sun'
	| 'other'
	| ''

export interface LensmeterDataWithAccuracy {
	accuracy: AccuracyValue
	OD: LensmeterSingleEye
	OS: LensmeterSingleEye
}

export type LensType =
	| ''
	| 'SingleVisionDistance'
	| 'Progressive'
	| 'Bifocal'
	| 'Computer'
	| 'SingleVisionNear'
	| 'Trifocal'
	| 'Sunglasses'

export type LensDesignType =
	| ''
	| 'aspheric'
	| 'standard'
	| 'standard-7x28'
	| 'standard-8x35'

export type LensMaterialType =
	| ''
	| 'cr39'
	| 'glass'
	| 'hi-index'
	| 'mid-index'
	| 'polycarbonate'
	| 'trivex'

export interface LensmeterData {
	default: LensmeterDataWithAccuracy
	visualAcuity: VisualAcuityData
	other?: LensmeterDataWithAccuracy
	examStatus?: ExamStatus
	pupillaryDistance?: string
	lensType: LensType
	use: LensUse
	useNote: string
}

export interface HistoricalRXData extends LensmeterData {
	lensAddOns?: LensAddOnsData
	lensMaterial?: LensMaterialData
	lensDesign?: LensDesignData
}

export interface InstrumentRemoteMedia {
	name: string
	eye: Eye
	mode?: DayOrNight
	format: string
	path?: string
	data?: string
	examStatus?: ExamStatus
	createdAt?: string
	note?: string
	key?: string
}

export interface GenericMedia
	extends Omit<Partial<InstrumentRemoteMedia>, 'eye' | 'mode'> {}
export interface VisualFieldsData {
	OS: {
		data: InstrumentRemoteMedia[]
		note: string
	}
	OD: {
		data: InstrumentRemoteMedia[]
		note: string
	}
}

export interface PachymetryData {
	OS: {
		data: InstrumentRemoteMedia[]
		note: string
	}
	OD: {
		data: InstrumentRemoteMedia[]
		note: string
	}
}

export interface AutorefractionSingleEye {
	sphere: string | null
	cyl: string | null
	axis: string
	pupils: string
}

export interface AutorefractionDataWithAccuracy {
	accuracy: AccuracyValue
	eyes: {
		OD: AutorefractionSingleEye
		OS: AutorefractionSingleEye
	}
}

export interface AutorefractionDataDayOrNight {
	default: AutorefractionDataWithAccuracy
	other?: AutorefractionDataWithAccuracy
}

export interface AutorefractionData {
	day: AutorefractionDataDayOrNight
	night?: AutorefractionDataDayOrNight
	pupillaryDistance?: string
	summary?: InstrumentRemoteMedia[]
	crystalline?: InstrumentRemoteMedia[]
	topography: InstrumentRemoteMedia[]
	visualSimulation?: InstrumentRemoteMedia[]
	examStatus?: ExamStatus
	accommodation?: {
		R: { equivalentSphereAmplitude: number }
		L: { equivalentSphereAmplitude: number }
	}
}

export interface TonometerDataEye {
	iop1: string
	iop2: string
	iopc: string
	time?: number
}

export interface TonometerData {
	OD: TonometerDataEye
	OS: TonometerDataEye
	examStatus?: ExamStatus
	pachymetry?: PachymetryData
	manual?: PachymetryManualData
	osNote?: string
	odNote?: string
	method?: string
	doctorNote?: string
	notes?: string
}

export interface NctTonometerData extends TonometerData {
	doctorNote: string
	manual?: ManualPachymetryData
}

export type AccuracyValue = '0.01' | '0.25'

export type AccuracyKey = 'default' | 'other'

export type RX_EYES = 'OD' | 'BIN' | 'OS'

export interface PhoropterDataEyes {
	OD: PhoropterSingleEye
	OS: PhoropterSingleEye
}

export interface PhoropterDataWithAccuracy {
	accuracy: AccuracyValue
	eyes: PhoropterDataEyes
	both: {
		distance: string
		distanceAdditional?: string
		near: string
		nearAdditional?: string
	}
	visualAcuity: {
		OD: string
		ODAdditional?: string
		OS: string
		OSAdditional?: string
	}
}

export interface PhoropterDataDayOrNight {
	default: PhoropterDataWithAccuracy
	other?: PhoropterDataWithAccuracy
}

export interface PhoropterDataPart {
	day: PhoropterDataDayOrNight
	night?: PhoropterDataDayOrNight
	updatedAt?: number
	note: string
	ph?: {
		R: string
		L: string
		B: string
	}
	glare?: {
		R: string
		L: string
		B: string
	}
	sensitivity?: number
	pupillaryDistance?: string
}

export interface PhoropterData {
	examStatus?: ExamStatus
	subjectiveRefraction?: PhoropterDataPart
	cycloplegicRefraction?: PhoropterDataPart
	finalRefraction?: PhoropterDataPart
	contactLenses?: PhoropterDataPart
	pupillaryDistance?: string
	sensitivity?: number
}

export interface KeratormeterDataEye {
	power: string
	hMeridian: string
	vPower: string
	vMeridian: string
}

export interface KeratometerDataWithAccuracy {
	accuracy: AccuracyValue
	OD: KeratormeterDataEye
	OS: KeratormeterDataEye
}

export interface KeratometerDataDayOrNight {
	default: KeratometerDataWithAccuracy
	other?: KeratometerDataWithAccuracy
}
export interface KeratometerData {
	day: KeratometerDataDayOrNight
	night?: KeratometerDataDayOrNight
	examStatus?: ExamStatus
}

export interface SlitLampMedia extends InstrumentRemoteMedia {
	contactLensId: string
}

export interface SlitLampData {
	OS: {
		data: SlitLampMedia[]
		note: string
	}
	OD: {
		data: SlitLampMedia[]
		note: string
	}
}

export enum InstrumentInRoom {
	Autorefraction = 'autorefraction',
	Keratometer = 'keratometer',
	Lensmeter = 'lensmeter',
	Tonometer = 'tonometer',
	Oct = 'oct',
	Phoropter = 'phoropter',
	RetinalImaging = 'retinalImaging',
	SlitLamp = 'slitLamp',
	VisualFields = 'visualFields',
}

export interface InstrumentsData {
	[InstrumentInRoom.Lensmeter]: LensmeterData
	[InstrumentInRoom.Autorefraction]: AutorefractionData
	[InstrumentInRoom.Keratometer]: KeratometerData
	[InstrumentInRoom.Oct]: OCTData
	[InstrumentInRoom.Tonometer]: TonometerData
	[InstrumentInRoom.Phoropter]: PhoropterData
	[InstrumentInRoom.VisualFields]: VisualFieldsData
	[InstrumentInRoom.SlitLamp]: SlitLampData
	[InstrumentInRoom.RetinalImaging]: RetinalImageData
}

export interface UpdateInstrumentData {
	type: keyof InstrumentsData
	value: any //TODO: insert the instrument data model here
}

export interface PreTestData {
	instruments: InstrumentsData
	manual: ManualData
}

export type TrendsOverTimeInstrument = {
	key: string
	OD?: PhoropterSingleEye
	OS?: PhoropterSingleEye
}
