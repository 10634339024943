import {
	Components,
	SimplePaletteColorOptions,
	Theme,
} from '@mui/material/styles'
import type {} from '@mui/x-date-pickers-pro/themeAugmentation'

import { palette } from './themePalette'

export const themeComponents: Components<Omit<Theme, 'components'>> = {
	MuiDateRangePickerDay: {
		styleOverrides: {
			day: ({ selected }) => ({
				...(Boolean(selected) && {
					backgroundColor: `${
						(palette?.secondary as SimplePaletteColorOptions)?.main
					} !important`,
				}),
			}),
			rangeIntervalDayHighlight: {
				backgroundColor: (palette?.secondary as SimplePaletteColorOptions)
					?.main,
			},
			dayInsideRangeInterval: {
				backgroundColor: (palette?.secondary as SimplePaletteColorOptions)
					?.main,
			},
		},
	},
}
